import React from 'react'
import { IoBus } from 'react-icons/io5'

type Props = {}

const Legend = (props: Props) => {
  return (
    <>
      <div className='legend'>
        <div>
          <IoBus size={'2.2rem'} />
          <p className='legend-title'>Transit Junction</p>
        </div>
        <div>
          <span className='legend-bus'></span><p>Bus</p>
        </div>
        <div>
          <hr className='legend-line'></hr><p>Route</p>
        </div>
      </div>
      <div className='portfolio-link'>
        <p>Built By <a href="https://shaneducksbury.com" target="_blank">Shane Ducksbury</a></p>
      </div>
    </>
  )
}

export default Legend