import React, { useEffect, useState } from 'react'
import { RouteDetails, RoutesWithTimestamp } from '../interfaces';
import { IoPlay, IoPlayBack, IoPlayForward, IoCloseCircle, IoPlaySkipForward, IoPlaySkipBack, IoPause } from "react-icons/io5";

type Props = {
    timestamps: RoutesWithTimestamp[] | undefined;
    currentTimestamp: number;
    clickedId: string | null;
    getRouteDetailFromRouteId: (routeId: string) => RouteDetails | null;
    setHoveredId: (routeId: string | null) => void;
    updateClickId: (routeId: string) => void;
    setTimestampForFilter: (direction: number) => void;
    clearSelection: (bool: boolean) => void;
    skipTimestamps: (direction: number) => void;
    lockSkipButtons: boolean;
    setMapPlay: (play: boolean) => void;
    play: boolean;
}

const Sidebar = ({ timestamps, currentTimestamp, getRouteDetailFromRouteId, setHoveredId, updateClickId, clickedId, setTimestampForFilter, clearSelection, skipTimestamps, lockSkipButtons, setMapPlay, play }: Props) => {

    // const [timestamp, setTimestamp] = useState<number>();
    const [route, setRoute] = useState<RoutesWithTimestamp>();
    const [selectedRouteCount, setSelectedRouteCount] = useState<number>();

    useEffect(() => {
        updateContent();
    }, [timestamps, currentTimestamp, clickedId])

    const updateContent = () => {
        const found = timestamps?.filter(timestamp => timestamp.timestamp === currentTimestamp);
        if(found && found.length > 0){
            found[0].routes.sort((a, b) => b.count > a.count ? 1 : -1)
            setRoute(found[0])
            if(clickedId){
                const foundRoute = found[0].routes.find(route => route.route === clickedId);
                if(foundRoute){
                    setSelectedRouteCount(foundRoute.count)
                }
            }
        }
    }

    const routeButton = (route: string, count: number) => {
        const details = getRouteDetailFromRouteId(route)
        if(details){
            return(<li 
                key={route}
                onMouseEnter={() => {
                    setHoveredId(route)
                }}
                onMouseLeave={() => {
                    setHoveredId(route)
                }}
                onClick={() => {
                    updateClickId(route);
                    // setSelectedRoute(route);
                    setSelectedRouteCount(count);
                }}>
                    <span className='route-button-text'><strong>{details.routeShortName}</strong>: {details.routeLongName}</span>
                    <br />
                    <span>Buses On Route Now: {count}</span>
                 </li>)
        }
    }

    const showSelectedRoute = (route: string | null = null, count: number | null = null) => {
        if(route && count){
        const details = getRouteDetailFromRouteId(route)
        return(<div className='selected-route-wrapper'>
                <div>
                    <p><strong>{details?.routeShortName}</strong>: {details?.routeLongName}</p>
                    <p>Buses On Route Now: {count}</p>
                </div>
                <button ><IoCloseCircle onClick={e => clearSelection(true)} size="1.5rem" /></button>
            </div>)
        } else {
            return (
                <div className='selected-route-wrapper'>
                    <div>
                        <p>Select a route to see details</p>
                    </div>
                </div>
            )
        }
    }

    const getHumanTime = (timestamp: number): string => {
        const d = new Date(timestamp * 1000);
        const seconds = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds()
        const minutes = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()
        const hours = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()
        return `${hours}:${minutes}:${seconds}`
      }

    const getHumanDate = (timestamp: number): string => {
    const d = new Date(timestamp * 1000);
    return `${d.toDateString()}`
    }

    const toggleMapPlay = () => {
        setMapPlay(!play)
    }

    const getButtons = () => {
        return(
          <div className="time-controller">
            <h3>Current Selected Time:</h3>
            <h1>{getHumanTime(currentTimestamp)}</h1>
            <p>{getHumanDate(currentTimestamp)}</p>
            <div className='time-buttons'>
                <button onClick={e => skipTimestamps(-1)}><IoPlaySkipBack size='1.5rem' /></button>
                <button onClick={e => !lockSkipButtons ? setTimestampForFilter(-1) : null} disabled={lockSkipButtons}><IoPlayBack size='1.5rem' /></button>
                <button onClick={e => toggleMapPlay()}>
                    {play ? <IoPause size='1.5rem' /> : <IoPlay size='1.5rem' />}
                    </button>
                <button onClick={e => setTimestampForFilter(1)}><IoPlayForward size='1.5rem' /></button>
                <button onClick={e => !lockSkipButtons ? skipTimestamps(1) : null} disabled={lockSkipButtons}><IoPlaySkipForward size='1.5rem' /></button>
            </div>
          </div>
        )
      }

    return (
        <div className='sidebar'>
            {getButtons()}
            <hr />
            <h4>Selected Route</h4>
            {clickedId && selectedRouteCount ? showSelectedRoute(clickedId, selectedRouteCount) : showSelectedRoute()}
            <hr />
            <h4>Routes By Total Number of Buses</h4>
            <div className='route-list'>
                <ul>{route && !clickedId ? route.routes.map(route => { return(routeButton(route.route, route.count))}) : <p>Clear the selection above to see all routes</p>}</ul>
            </div>
        </div>
    )
}

export default Sidebar