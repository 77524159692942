import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AllRoutesWithTimestamps, HourlyTimestamps, RouteDetails, RoutesWithTimestamp } from '../interfaces';
import Legend from './Legend';
import LoadingModal from './LoadingModal';
import Map from './Map'
import Sidebar from './Sidebar';

type Props = {}

const { REACT_APP_API_URL } = process.env

const API_URL = REACT_APP_API_URL

const BusMap = (props: Props) => {
    const [mapLoaded, setMapLoaded] = useState<boolean>(false);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [hoveredId, setHoveredId] = useState<string | null>(null);
    const [clickedId, setClickedId] = useState<string | null>(null);

    const [hourlyTimestamps, setHourlyTimestamps] = useState<HourlyTimestamps>();
    const [currentHourlyTimestamp, setCurrentHourlyTimestamp] = useState<string>();
    const [nextHourlyTimestamp, setNextHourlyTimestamp] = useState<string>();

    const [currentSelectedLayer, setCurrentlySelectedLayer] = useState<string>('positions2');

    const [timestampsWithRoutes, setTimestampsWithRoutes] = useState<RoutesWithTimestamp[]>();
    const [currentTimestamp, setCurrentTimestamp] = useState<any>();

    const [prevPosStale, setPrevPosStale] = useState<boolean>(false)

    const [busRoutes, setBusRoutes] = useState<GeoJSON.FeatureCollection<any, GeoJSON.GeoJsonProperties>>();
    const [routeDetails, setRouteDetails] = useState<RouteDetails[]>();

    const [allTimestampData, setAllTimestampData] = useState<AllRoutesWithTimestamps>();

    const [clearSelection, setClearSelection] = useState<boolean>(false)

    const [lockSkipButtons, setLockSkipButtons] = useState<boolean>(false);
    const [play, setPlay] = useState<boolean>(false);
    const [nextFrame, setNextFrame] = useState<boolean>(false);
        
    
    const getDataFromApi = async (url: string): Promise<any> => {
        return await axios.get(url)
        .then(res => { return res.data });
      }
    
    const initialiseHourlyTimestamps = async (): Promise<void> => {
        const ts = await getDataFromApi(`${API_URL}/timestamps.json`);
        setHourlyTimestamps(ts);
    }

    const initialiseRoutes = async (): Promise<void> => {
        const busRoutes = await getDataFromApi(`${API_URL}/bus_routes_from_routes.geojson`);
        setBusRoutes(busRoutes);
    }

    const initialiseAllTimestamps = async (): Promise<void> => {
        const tsData = await getDataFromApi(`${API_URL}/timestamp_route_data.json`);
        setAllTimestampData(tsData);
    }


    const updateMapLoaded = (status: boolean) => {
        setMapLoaded(true);
    }

    const updateHoveredId = (hoveredId: string | null) => {
        setHoveredId(hoveredId);
    }

    const updateClickedId = (clickedId: string | null) => {
        setClickedId(clickedId);
    }

    const updateClearSelection = (bool: boolean) => {
        setClearSelection(bool);
    }

    const updatePrevPosStale = (bool: boolean) => {
        setPrevPosStale(bool);
    }

    const generateRouteDetails = () => {
        const routeDetails: RouteDetails[] = []
        busRoutes?.features.forEach(feature => {
          routeDetails.push(
            {
              routeId: feature.properties?.route_id,
              routeShortName: feature.properties?.route_short_name,
              routeLongName: feature.properties?.route_long_name,
              routeDesc: feature.properties?.route_desc,
              routeType: feature.properties?.route_type,
            }
          )
        })
        setRouteDetails(routeDetails);
      }
    
    const getRouteDetailFromRouteId = (routeId: string) => {
        const found = routeDetails?.find(item => item.routeId === routeId);
        if(found) return found;
        return null;
    }

    const findIndexOfCurrentHourlyTimestamp = () => {
        return hourlyTimestamps?.timestamps.findIndex(timestamp => timestamp === currentHourlyTimestamp);
      }
    
    const updateCurrentHourlyTimestamp = (direction: number) => {
        const foundTsIdx = findIndexOfCurrentHourlyTimestamp();
        if(foundTsIdx !== undefined){
            const cht = hourlyTimestamps?.timestamps.at(foundTsIdx + direction)
            if(cht === undefined) {
                setCurrentHourlyTimestamp(hourlyTimestamps?.timestamps.at(0));
                setNextHourlyTimestamp(hourlyTimestamps?.timestamps.at(1));
                return;
            }
            setCurrentHourlyTimestamp(hourlyTimestamps?.timestamps.at(foundTsIdx + direction));
            const nht = hourlyTimestamps?.timestamps.at(foundTsIdx + direction + 1)
            if(nht === undefined) return;
            setNextHourlyTimestamp(nht)
            return;
        }
        if(foundTsIdx === 0) return;
    }

    const setTimestampForFilter = (direction: number) => {
        const timestampIndex = timestampsWithRoutes?.findIndex(item => item.timestamp === currentTimestamp);
    
        if(direction === 1 && timestampIndex !== undefined){
            const nextTimestamp = timestampsWithRoutes?.at(timestampIndex + 1);
            if(nextTimestamp === undefined){
                updateCurrentHourlyTimestamp(1);
            }
            const newTimestamp = timestampsWithRoutes?.at(timestampIndex + 1)
            if(newTimestamp) setCurrentTimestamp(newTimestamp.timestamp)
        }
    
        if(direction === -1 && timestampIndex !== undefined){
            if(timestampIndex !== 0) setCurrentTimestamp(timestampsWithRoutes?.at(timestampIndex - 1)?.timestamp)
            const foundTsIdx = findIndexOfCurrentHourlyTimestamp();
            if(foundTsIdx){
                if(timestampIndex === 0){
                updateCurrentHourlyTimestamp(-1);
                setPrevPosStale(true);
                return;
                }
                }
            }
      }
    
    const skipTimestamps = (direction: number) => {
        if(direction === 1){
            updateCurrentHourlyTimestamp(1);
        }
        if(direction === -1){
            const ts = timestampsWithRoutes?.at(0)?.timestamp
            if(ts) setCurrentTimestamp(ts)
        }
    }

    const updateSelectedLayer = (layerSelection: string) => {
        setCurrentlySelectedLayer(layerSelection)
    }

    const setMapPlay = (play: boolean) => {
        setPlay(play);
    }

    useEffect(() => {
        initialiseHourlyTimestamps();
        initialiseRoutes();
        initialiseAllTimestamps();
    },[]);

    useEffect(() => {
        if(hourlyTimestamps){
            setCurrentHourlyTimestamp(hourlyTimestamps.timestamps.at(0))
            setNextHourlyTimestamp(hourlyTimestamps.timestamps.at(1))
        }
    },[hourlyTimestamps])

    useEffect(() => {
        if(currentHourlyTimestamp){
            const foundTimestamp = allTimestampData?.timestampRouteData[currentHourlyTimestamp]
            setTimestampsWithRoutes(foundTimestamp)
            if(foundTimestamp) setCurrentTimestamp(foundTimestamp.at(0)?.timestamp)
            if(prevPosStale){
                const newTimestamp = foundTimestamp?.at(-1)
                if(newTimestamp) setCurrentTimestamp(newTimestamp.timestamp);
                return;
            }
        }
    },[allTimestampData, currentHourlyTimestamp])

    useEffect(() => {
        if(busRoutes){
            generateRouteDetails();
        }
    },[busRoutes])

    useEffect(() => {
        if(play){
            const updateId = setInterval(() => {
                setNextFrame(true)
            }, 250)
            return () => clearInterval(updateId);
        }
    }, [play])

    useEffect(() => {
        if(nextFrame){
            if(currentTimestamp !== 0){
            setTimestampForFilter(1);
            }
            setNextFrame(false);
        }
    }, [nextFrame])

    useEffect(() => {
        if(timestampsWithRoutes){
            if(!dataLoaded){
                setDataLoaded(true);
            }
        }
    },[timestampsWithRoutes])


    return (
        <div>
            <LoadingModal loading={!dataLoaded} />
            {
            currentHourlyTimestamp ? 
            <Map 
               updateMapLoaded={updateMapLoaded}
               updateHoveredId={updateHoveredId}
               updateClickedId={updateClickedId}
               updateSelectedLayer={updateSelectedLayer}
               updateClearSelection={updateClearSelection}
               updatePrevPosStale={updatePrevPosStale}
               selectedLayer={currentSelectedLayer}
               currentHourlyTimestamp={currentHourlyTimestamp}
               nextHourlyTimestamp={nextHourlyTimestamp}
               mapLoaded={mapLoaded}
               currentTimestamp={currentTimestamp}
               clearSelection={clearSelection}
               hoveredId={hoveredId}
               clickedId={clickedId}
               timestampsWithRouteCount={timestampsWithRoutes}
               busRoutes={busRoutes}
               prevPosStale={prevPosStale}
            />
            : <p>Nothing Yet.</p>
            }
            <div>
            <Sidebar 
                timestamps={timestampsWithRoutes} 
                currentTimestamp={currentTimestamp} 
                clickedId={clickedId}
                getRouteDetailFromRouteId={getRouteDetailFromRouteId}
                setHoveredId={updateHoveredId}
                updateClickId={updateClickedId}
                setTimestampForFilter={setTimestampForFilter}
                clearSelection={updateClearSelection}
                skipTimestamps={skipTimestamps}
                lockSkipButtons={lockSkipButtons}
                setMapPlay={setMapPlay}
                play={play}
                />
            </div>
            <Legend />
        </div>
    )
}

export default BusMap