import React, { useEffect, useRef, useState } from 'react'
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { RoutesWithTimestamp } from '../interfaces';
const { REACT_APP_MAPBOX_TOKEN } = process.env;
const { REACT_APP_API_URL } = process.env

const API_URL = REACT_APP_API_URL

mapboxgl.accessToken = REACT_APP_MAPBOX_TOKEN ?? '';

type Props = {
    updateMapLoaded: (status: boolean) => void;
    updateHoveredId: (hoveredId: string | null) => void;
    updateClickedId: (clickedId: string | null) => void;
    updateSelectedLayer: (layerSelection: string) => void;
    updateClearSelection: (bool: boolean) => void;
    updatePrevPosStale: (bool: boolean) => void;
    selectedLayer: string;
    currentHourlyTimestamp: string | undefined;
    nextHourlyTimestamp: string | undefined;
    mapLoaded: boolean;
    currentTimestamp: string | undefined;
    clearSelection: boolean;
    hoveredId: string | null;
    clickedId: string | null;
    timestampsWithRouteCount: RoutesWithTimestamp[] | undefined;
    busRoutes: GeoJSON.FeatureCollection<any, GeoJSON.GeoJsonProperties> | undefined;
    prevPosStale: boolean;
}


const Map = ({ updateMapLoaded, updateClickedId, updateHoveredId, updateSelectedLayer, updateClearSelection, updatePrevPosStale, selectedLayer, currentHourlyTimestamp, nextHourlyTimestamp, mapLoaded, currentTimestamp, clearSelection, hoveredId, clickedId, timestampsWithRouteCount, busRoutes, prevPosStale }: Props) => {
    const mapContainer = useRef<any>(null);
    const map = useRef<any>();
    const [lng, setLng] = useState(151.2103);
    const [lat, setLat] = useState(-33.857961);
    const [zoom, setZoom] = useState(12);

    const initialiseMap = () => {
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/mapbox/dark-v10',
          center: [lng, lat],
          zoom: zoom
          });
        map.current.on("style.load", () => {
          loadRouteLayer();
          loadPositionLayer();
          updateMapLoaded(true);
        })
        map.current.on("load", () => {
        })
        map.current.on('mousemove', 'positions1', (e: any) => {
          updateHoveredId(e.features[0].properties.routeId);
        })
        map.current.on('mousemove', 'positions2', (e: any) => {
          updateHoveredId(e.features[0].properties.routeId);
        })
        map.current.on('click', 'positions1', (e: any) => {
          updateClickedId(e.features[0].properties.routeId)
        })
        map.current.on('click', 'positions2', (e: any) => {
          updateClickedId(e.features[0].properties.routeId)
        })
      }
    
    const loadRouteLayer = () => {
        map.current?.addSource('route', {
            'type': 'geojson',
            'data': `${API_URL}/bus_routes_from_routes.geojson`,
            'promoteId': 'route_id',
            'buffer': 0
        });
        map.current?.addLayer({
            'id': `route`,
            'type': 'line',
            'source': 'route',
            'layout': {
            'line-join': 'round',
            'line-cap': 'round'
            },
            'paint': {
            'line-color': ['case', 
            ['boolean', ['feature-state', 'hover'], false],'rgba(208, 23, 230, 0.7)',
            ['boolean', ['feature-state', 'clicked'], false],'rgba(10, 132, 255, 1)', 
            'rgba(10, 132, 255, 0.05)'
            ],
            'line-width': 2,
            }
        })
    }

    const loadPositionLayer = () => {
        map.current?.addSource('positions1', {
          'type': 'geojson',
          'data': `${API_URL}/${currentHourlyTimestamp}.geojson`,
          'buffer': 0
        });
        map.current?.addLayer({
          'id': `positions1`,
          'type': 'circle',
          'source': 'positions1',
          'paint': {
            'circle-color': "#f54242"
          }
        })
        map.current?.addSource('positions2', {
          'type': 'geojson',
          'data': `${API_URL}/${nextHourlyTimestamp}.geojson`,
          'buffer': 0
        });
        map.current?.addLayer({
          'id': `positions2`,
          'type': 'circle',
          'source': 'positions2',
          'paint': {
            'circle-color': "#f54242"
          }
        })
        if(currentTimestamp) setPositionFilter(+currentTimestamp);
        setRoutesFilter();
    }

    const setPositionFilter = (timestamp: number) => {
        map.current.setFilter('positions1', ['==', ['number', ['get', 'timestamp']], timestamp])
        map.current.setFilter('positions2', ['==', ['number', ['get', 'timestamp']], timestamp])
    }

    
    const setPositionFilterFromRoute = (routeId: string) => {
        map.current.setFilter('positions1', [
            'all',
            ['==', ['string', ['get', 'routeId']], routeId],
            ['==', ['number', ['get', 'timestamp']], currentTimestamp]
        ])
        map.current.setFilter('positions2', [
            'all',
            ['==', ['string', ['get', 'routeId']], routeId],
            ['==', ['number', ['get', 'timestamp']], currentTimestamp]
        ])
    }

    const setRoutesFilterFromArray = (routes: string[]) => {
        map.current.setFilter('route', ['match', ['get', 'route_id'], routes.map((item: any) =>{ return item }), true, false])
    }
    
    const setRoutesFilter = () => {
        if(currentTimestamp){
            const foundTimestamp = timestampsWithRouteCount?.find(item => item.timestamp === +currentTimestamp)
            const collectedRoutes: any = [];
            if(foundTimestamp){
                foundTimestamp.routes.forEach(route => {
                collectedRoutes.push(route.route.toString())
                })
            }
            setRoutesFilterFromArray(collectedRoutes);
        }
    }

    const updateMapSource = () => {
        if(selectedLayer === 'positions1'){
            map.current.getSource('positions2').setData(`${API_URL}/${nextHourlyTimestamp}.geojson`);
            updateSelectedLayer('positions2')
        } else {
            map.current.getSource('positions1').setData(`${API_URL}/${nextHourlyTimestamp}.geojson`);
            updateSelectedLayer('positions1')
        }
        if(currentTimestamp)setPositionFilter(+currentTimestamp);
        setRoutesFilter();
    }

    const clearCurrentSelection = () => {
        map.current.setFeatureState({'source': 'route', 'id': clickedId},{'clicked': false})
        updateClickedId(null);
    }

    const setHoverState = (routeId: string | null) => {
        if(routeId){
          map.current.setFeatureState({'source': 'route', 'id': routeId},{'hover': true})
        }
        // updateHoveredId(routeId)
    }

    const setMapBounds = (feature: any) => {
        const coordinates = feature?.geometry.coordinates
        if(!coordinates) return
        let bounds: any;
        if(feature.geometry && feature.geometry.type === 'MultiLineString'){
          bounds = new mapboxgl.LngLatBounds(
            coordinates[0][0],
            coordinates[0][0]
          );
          coordinates.forEach((root: any) => {
            root.forEach((coord: any) => {
              bounds.extend(coord);
            })
          });
        }
        if(feature.geometry.type === 'LineString'){
          bounds = new mapboxgl.LngLatBounds(
            coordinates[0],
            coordinates[0]
          );
          coordinates.forEach((coord: any) => {
              bounds.extend(coord);
          });
        }
        map.current.fitBounds(bounds, {
          padding: 150,
          offset: [-100, 0]
          });
        }


    useEffect(() => {
        if(!mapLoaded){
            // updateMapLoaded(true);
            initialiseMap();
        }
    }, [])

    useEffect(() => {
        if(currentTimestamp && mapLoaded){
            setPositionFilter(+currentTimestamp);
            // setRoutesFilter();
            if(!clickedId){
                setPositionFilter(+currentTimestamp);
                setRoutesFilter();
            }
            if(clickedId){
                setPositionFilterFromRoute(clickedId);
            }
        }
    },[currentTimestamp, mapLoaded])

    useEffect(() => {
        if(mapLoaded){
            updateMapSource();
            if(clickedId){
                setRoutesFilterFromArray([clickedId]);
                setPositionFilterFromRoute(clickedId);
            }
        }
    },[currentHourlyTimestamp])

    useEffect(() => {
        if(clearSelection){
            clearCurrentSelection();
            updateClearSelection(false);
        }
    },[clearSelection])

    useEffect(() => {
        setHoverState(hoveredId);
        if(hoveredId){
            map.current.on('mouseleave', 'route', (e: any) => {
              if(hoveredId){
                map.current.setFeatureState({'source': 'route', 'id': hoveredId},{'hover': false})
                updateHoveredId(null)
              }
            })
          }
    }, [hoveredId])

    useEffect(() => {
        if(clickedId){
          setRoutesFilterFromArray([clickedId]);
          setPositionFilterFromRoute(clickedId);
          map.current.setFeatureState({'source': 'route', 'id': clickedId},{'hover': false})
          map.current.setFeatureState({'source': 'route', 'id': clickedId},{'clicked': true})
    
          const feature = busRoutes?.features.find(element => element.properties?.route_id === clickedId)
          setMapBounds(feature)
        }
        if(!clickedId && mapLoaded && currentTimestamp){
            setPositionFilter(+currentTimestamp);
            setRoutesFilter();
        }
    }, [clickedId])

    useEffect(() => {
        if(prevPosStale){
          map.current.getSource(selectedLayer).setData(`${API_URL}/${currentHourlyTimestamp}.geojson`);
          updatePrevPosStale(false);
        }
    },[prevPosStale])


    return (
        <>
            <div ref={mapContainer} className="map-container" />
        </>
      )
}

export default Map