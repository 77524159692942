import React, { useEffect } from 'react';
import './App.css';
import BusMap from './components/BusMap';

function App() {
  useEffect(() => {
    document.title = "Transit Junction";  
  }, []);


  return (
    <div className="App">
      <BusMap />
    </div>
  );
}

export default App;
