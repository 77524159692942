import React from 'react'
import ReactModal from 'react-modal';
import { BarLoader } from 'react-spinners';
import { IoBus } from "react-icons/io5";

type Props = {
    loading: boolean;
}

const LoadingModal = ({ loading }: Props) => {
  return (
    <>
        <ReactModal 
        isOpen={loading}
        style={{
          content: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: 'none',
            borderRadius: 0,
            background: 'rgba(0, 0, 0, 0.97)',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
            padding: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        }}
        ariaHideApp={false}
      >
        <div className='loading-wrapper'>
            <IoBus size={'10rem'} />
            <h1>Transit Junction</h1>
            <BarLoader 
                color='rgb(94, 92, 230)'
            /> 
        </div>
      </ReactModal>
    </>
  )
}

export default LoadingModal